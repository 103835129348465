.navbar-default{
    margin-bottom: 0 !important;
   background-color: grey;
}

.logo{
    object-fit: cover;
    object-position: 100% 50%;
  
    width: 100%;
    height: 100px;
    border: 1;
    padding: 0;
    margin: 0;
    box-shadow: 15px;
  }