.content-wrapper{
    width: 100%;
    
    position: relative;
    margin-bottom: 2%;
}

.content-wrapper img{
    padding-top: 50px;
    width: 100%;  
    height: 100%;  
   
}

.heading{
    position: absolute;
    color: rgb(216, 122, 23, 1);
    top: 30%;
    text-align: center;
    text-shadow: 10em;
    width: 100%;
    font-size: 5rem;
}

.sub-heading{
    position: absolute;
    color: rgb(0, 0, 0);
    top: 50%;
    text-align: center;
    width: 100%;
    font-size: 2rem;
}
.header-img{
    width: 100%;
    margin-top: -2em;
    margin-bottom: 2em;
}
.contact-list{
    text-align: left;
    list-style-position: inside;
}
.contact-list li{
   margin-top: 15px ;
    
}

.animate-charcter
{
   text-transform: uppercase;
  background: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 120px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}