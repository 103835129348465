
.content-wrapper{
    width: 100%;
    position: relative;
    margin-bottom: 2%;
}

.content-wrapper img{
    padding-top: 50px;
    width: 100%;
    height: 100%;    
}

.heading{
    position: absolute;
    color: rgb(63, 86, 66);
    top: 50%;
    text-align: center;
    width: 100%;
    font-size: 5rem;
}

.header-img{
    width: 100%;
    margin-top: -2em;
    margin-bottom: 2em;
}

.about-pc{
    float:left;
    margin: 0 1em 1em 0;
    width: 50%
}

.team{
    background-color: rgb(216, 216, 216);
    height: 80vh;
}

.profile-pic{
    object-fit: cover;
    object-position: 60% 30%;
  
    width: 100px;
    height: 100px;
    border: 0;
    padding: 0;
    margin: 0;
}
@media all {
    .page-break {
      display: none;
    }
  }
  
