.icons{
    padding-bottom: 20px;
    border-bottom:1px solid #cccccc;     
    line-height: 30px;
   
    
}
.social-icons{
    display: flex;
    justify-content: center;
    
}
.social-icon{
    border: 0;
    padding: 0;  
    margin: 15px;
    margin-left: 15px;
}
.main-icons{
    display: flex;
    justify-content: left;
}
.main-icon{
    border: 0;
    padding: 0;
    height: 175px;
    width: auto;
    margin: 5px;
    margin-left: 5px;
}

.info-footer{
    color: #666666;
    font-family: Arial, Verdana, Trebuchet , sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    
}

.footer{
    margin-top: 5rem;
}

/* for desktop */
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}

.main {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5em;
  }
  
  .up {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
  
  .down {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
  
  .card1 {
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: white;
    border-radius: 90px 5px 5px 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .2s ease-in-out;
  }
  
  .instagram {
    margin-top: 1.5em;
    margin-left: 1.2em;
    fill: #cc39a4;
  }
  
  .card2 {
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: white;
    border-radius: 5px 90px 5px 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .2s ease-in-out;
  }
  
  .facebook {
    margin-top: 1.5em;
    margin-left: -.9em;
    fill: #03A9F4;
  }
  
  .card3 {
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: white;
    border-radius: 5px 5px 5px 90px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .2s ease-in-out;
  }
  
  .whatsapp {
    margin-top: -.6em;
    margin-left: 1.2em;
    fill: #00ff00;
  }
  
  .card4 {
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    background: white;
    border-radius: 5px 5px 90px 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: .2s ease-in-out;
  }
  
  .gmail {
    margin-top: -.9em;
    margin-left: -1.2em;
    fill: #f14336;
  }
  
  .card1:hover {
    cursor: pointer;
    scale: 1.1;
    background-color: #cc39a4;
  }
  
  .card1:hover .instagram {
    fill: white;
  }
  
  .card2:hover {
    cursor: pointer;
    scale: 1.1;
    background-color: #1877f2;
  }
  
  .card2:hover .facebook {
    fill: white;
  }
  
  .card3:hover {
    cursor: pointer;
    scale: 1.1;
    background-color: #00ff00;
  }
  
  .card3:hover .whatsapp {
    fill: white;
  }
  
  .card4:hover {
    cursor: pointer;
    scale: 1.1;
    background-color: #FF0004;
  }
  
  .card4:hover .gmail {
    fill: white;
  }