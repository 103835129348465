.header{
    background: URL('/public/assets/img/adventure.jpg') no-repeat center;
    background-size: 100%;
    width: 100%;
    height: 100vh;
    margin-bottom: 0em;
}
.header1{
    background: URL('/public/assets/img/2608588.jpg') no-repeat center;
    background-size: 100%;
    width: 100%;
    height: 100vh;
    margin-bottom: 0em;
}
.jumbotron{
    position: absolute;
    margin-bottom: 4em;
    background:rgba(102, 9, 83, 0.5); ;
    /* background-color: var(--container-corvid-background-color,rgba(var(--bg,var(--color_11)),var(--alpha-bg,1))); */
    top: 25%;
    color: #ffffff;
    text-align: flex;
    letter-spacing: 0.25cm;
    left: 25%;
}
.person-wrapper{
    margin-bottom: 2em;
}
.profile-pic{
    width:50%;
}

.btn-primary{
    background-image:none;
    border: none;
    border-radius: 0;
    background: #2892D7;
}
.h3{
    margin-bottom: 1em;
    background-image: -moz-repeating-radial-gradient(circle at center, #fff, #fff 5px, transparent 5px, transparent 10px, url('/public/assets/img/alleppey2.jpg') 10px);
}
.banner{
    margin-top: 50px;
    margin-bottom: 50px;
    border-bottom: 1px linear;
}

.testimonial{
    margin-top: 15px;
    margin-bottom: 55px;
}
.thumbnail_border {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .thumbnail_border1 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    width: 352px;
    height: 350px;
  }
  .my-2{
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
  .image-container {
    width: 75%; /* Set the width of the container to 75% */
    max-width: 100%; /* Ensure it doesn't exceed its parent's width */
  }
  
  .resized-image {    
    align-items: center;
    width: 100%; /* Make the image take up 100% of the container's width */
    height: auto; /* Maintain the image's aspect ratio */
  }
  .cta {
    display: flex;
    padding: 11px 33px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    color: white;
    background: #6225E6;
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(-15deg);
    border: none;
  }
  
  .cta:focus {
    outline: none;
  }
  
  .cta:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 #FBC638;
  }
  
  .cta .second {
    transition: 0.5s;
    margin-right: 0px;
  }
  
  .cta:hover  .second {
    transition: 0.5s;
    margin-right: 45px;
  }
  
  .span {
    transform: skewX(15deg)
  }
  
  .second {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%;
  }
  
  .one {
    transition: 0.4s;
    transform: translateX(-60%);
  }
  
  .two {
    transition: 0.5s;
    transform: translateX(-30%);
  }
  
  .cta:hover .three {
    animation: color_anim 1s infinite 0.2s;
  }
  
  .cta:hover .one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
  }
  
  .cta:hover .two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
  }
  
  @keyframes color_anim {
    0% {
      fill: white;
    }
  
    50% {
      fill: #FBC638;
    }
  
    100% {
      fill: white;
    }
  }
  
  :after,:before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .btn--huge {
    cursor: pointer;
    position: relative;
    height: 45px;
    background-color: #ff4e33;
    text-transform: uppercase;
    display: inline-block;
    color: fff;
    font-family: Arial;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0 20px 0 50px;
    white-space: nowrap;
  }
  
  .btn--huge:before {
    -webkit-transition: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: all 0.5s cubic-bezier(0.785, 0.15 , 0.15, 0.86);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #D6D4D3;
  }
  
  .btn--huge:after {
    -webkit-transition: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: all 0.5s cubic-bezier(0.785, 0.15 , 0.15, 0.86);
    content: '→';
    font-size: 14px;
    line-height: 1;
    color: #D6D4D3;
    position: absolute;
    left: 20px;
    top: 50%;
    width: 22px;
    height: 22px;
    margin-top: -11px;
    background: #FF4E33;
    z-index: 2;
    border-radius: 50%;
    border: 1px solid #D6D4D3;
  }
  
  .btn--huge__text {
    position: relative;
    top: -1px;
    -webkit-transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .btn--huge__text div {
    position: relative;
    color: #fff;
  }
  
  .btn--huge__text span {
    -webkit-transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    top: 0;
    left: 0;
    color: #000;
    width: 0;
  }
  
  .btn--huge:hover:after {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  
  .btn--huge:hover:before {
    width: 0;
  }
  
  .btn--huge:hover .btn--huge__text span {
    width: 100%;
  }

  button {
  position: relative;
  padding: 12px 35px;
  background: #FEC195;
  font-size: 17px;
  font-weight: 500;
  color: #181818;
  border: 3px solid #FEC195;
  border-radius: 8px;
  box-shadow: 0 0 0 #fec1958c;
  transition: all .3s ease-in-out;
}

.star-1 {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 25px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
}

.star-2 {
  position: absolute;
  top: 45%;
  left: 45%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-3 {
  position: absolute;
  top: 40%;
  left: 40%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-4 {
  position: absolute;
  top: 20%;
  left: 40%;
  width: 8px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all .8s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-5 {
  position: absolute;
  top: 25%;
  left: 45%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all .6s cubic-bezier(0, 0.4, 0, 1.01);
}

.star-6 {
  position: absolute;
  top: 5%;
  left: 50%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all .8s ease;
}

button:hover {
  background: transparent;
  color: #FEC195;
  box-shadow: 0 0 25px #fec1958c;
}

button:hover .star-1 {
  position: absolute;
  top: -80%;
  left: -30%;
  width: 25px;
  height: auto;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

button:hover .star-2 {
  position: absolute;
  top: -25%;
  left: 10%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

button:hover .star-3 {
  position: absolute;
  top: 55%;
  left: 25%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

button:hover .star-4 {
  position: absolute;
  top: 30%;
  left: 80%;
  width: 8px;
  height: auto;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

button:hover .star-5 {
  position: absolute;
  top: 25%;
  left: 115%;
  width: 15px;
  height: auto;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

button:hover .star-6 {
  position: absolute;
  top: 5%;
  left: 60%;
  width: 5px;
  height: auto;
  filter: drop-shadow(0 0 10px #fffdef);
  z-index: 2;
}

.fil0 {
  fill: #FFFDEF
}