body {
    background: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
  }
  
  h1 {
    color: #fff;
    font-weight: normal;
    font-size: 2.5rem;
    text-align: center;
  }
  
  .readme {
    color: #fff;
    margin: 0 auto;
    width: 80%;
    
    a {
      color: #00a486;
    }
  }
  
  /*_________________  Accordion
  ________________________________________*/
  .accordion {
    position: relative;
    margin: 60px auto;
    width: 80%;
  }
  
  [id*="open-accordion"], [id*="close-accordion"] {
    background: #00a486;
    border-bottom: 1px solid #fff;
    line-height: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
    position: relative;
    width: 99%;
  }
  
  [id*="close-accordion"] {
    display: none;
  }
  
  .accordion a {
    color: #fff;
    font-size: 1.25em;
    font-weight: normal;
    padding-left: 2%;
    text-decoration: none;
    text-shadow: none;
  }
  
  [id*="open-accordion"]:after, [id*="close-accordion"]:after {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(255, 255, 255, 0.6);
    position: absolute;
    right: 5px;
    top: 15px;
    z-index: 999;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
  }
  
  .target-fix {
    display: block;
    top: 0;
    left: 0;
    position: fixed;
  }
  
  .accordion-content {
    background: #fff;
    height: 0;
    margin: -1px auto 0;
    padding: 0 2.5%;
    position: relative;
    overflow: hidden;
    width: 90%;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
  }
  
  .accordion span:target ~ .accordion-content {
    display: block;
    height: auto;
    padding-bottom: 25px;
    padding-top: 10px;
  }
  
  .accordion span:target ~ [id*="close-accordion"] {
    display: block;
  }
  
  .accordion span:target ~ [id*="open-accordion"] {
    display: none;
  }
  
  .accordion span:target ~ [id*="close-accordion"]:after {
    border-top: 10px solid #333;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }