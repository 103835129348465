.content-wrapper{
    width: 100%;
    
    position: relative;
    margin-bottom: 2%;
}

.content-wrapper img{
  padding-top: 50px;
    width: 100%;  
    height: 100%;  
   
}

.heading{
    position: absolute;
    color: rgb(240, 240, 240);
    top: 30%;
    text-align: center;
    width: 100%;
    font-size: 5rem;
}

.sub-heading{
    position: absolute;
    color: rgb(224, 224, 224);
    top: 50%;
    text-align: center;
    width: 100%;
    font-size: 2rem;
}
.header-img{
    width: 100%;
    margin-top: -2em;
    margin-bottom: 2em;
}
.main-icons{
    display: flex;
    justify-content: center;
}
.main-icon{
    border: 0;
    padding: 0;
    height: 150px;
    width: auto;
    margin: 5px;
    margin-left: 5px;
}
.fa-taxi {
    color: rgb(247, 118, 25);
  }
  .fa-hotel {
    color: rgb(80, 247, 25);
  }
  .fa-ship {
    color: rgb(154, 87, 206);
  }
  .fa-plane-departure {
    color: rgb(197, 168, 25);
  }
  .thumbnail_border {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }

  .comic-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #ff5252;
    border: 2px solid #000;
    border-radius: 10px;
    box-shadow: 5px 5px 0px #000;
    transition: all 0.3s ease;
    scale: 0.9;
  }
  
  .comic-button:hover {
    background-color: #fff;
    color: #ff5252;
    border: 2px solid #ff5252;
    box-shadow: 5px 5px 0px #ff5252;
    scale: 1;
  }
  
  .comic-button:active {
    background-color: #fcf414;
    box-shadow: none;
    transform: translateY(4px);
  }
  